import React, { useEffect, useRef, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import { div } from 'framer-motion/client';

const Footer = () => {
    const footerRef = useRef(null);
    const navigate = useNavigate()

    const [disclaimer, setDisclaimer] = useState(false)

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])

    useEffect(() => {
        const div = document.querySelector(".footerAni");
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    div.classList.add("active")
                } else {
                    div.classList.remove("active");
                }
            },
            { rootMargin: "0px", threshold: 0.5 }
        );
        if (footerRef.current) {
            observer.observe(footerRef.current);
        }
        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, [footerRef]);

    return (
        <div>



            <div class=' position-relative' style={{ background: '#424242', overflow: 'hidden', zIndex: '1' }} ref={footerRef}>
                <div class='footerAni  col-12' >
                    <div class='col-sm-7 col-11 m-auto '>
                        <img src={require('../Images/footerBg.png')} style={{ width: '100%' }} alt="" />
                    </div>
                </div>
                <br />
                <div class='col-11 row text-white m-auto gap-md-0 gap-3'>
                    <div class='col-md-5'>
                        <div class=' col-md-10  text-start my-3'>
                            <img src={require('../Images/footerLogo.png')} style={{ height: '60px' }} alt="" />
                            <p class='mfs-2 mt-3 fwr'>3Sum is a revolutionary platform designed to make trading and investing accessible to everyone, especially beginners. Unlike traditional platforms that cater to advanced traders with complex tools.</p>
                            {/* <button class='sumBtn mfs-4 my-4 rounded-5'>Contact Us</button> */}
                        </div>

                    </div>
                    <div class='col-md-7'>
                        <div class='row gap-sm-0 gap-3' >
                            <div className='col-sm-5 col-11 text-start'>
                                <p class='fwsb mfs-3'>Pages</p>
                                <p type='button' class='mfs-2 mt-1 org' style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#exampleModal">Disclaimer</p>
                                <p class='mfs-2 mt-1' style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>Home</p>
                                <p class='mfs-2 mt-1' style={{ cursor: 'pointer' }} onClick={() => navigate('/our-story')}>Our Story</p>
                                <p class='mfs-2 mt-1' style={{ cursor: 'pointer' }} onClick={() => navigate('/our-products')}>Our Products</p>
                                <p class='mfs-2 mt-1' style={{ cursor: 'pointer' }} onClick={() => navigate('/sum-bot')}>Sum-Bot</p>
                                <p class='mfs-2 mt-1' style={{ cursor: 'pointer' }} onClick={() => navigate('/quantum-global')}>Portfolios 3.O</p>
                                <p class='mfs-2 mt-1' style={{ cursor: 'pointer' }} onClick={() => navigate('/ghost-trader')}>Ghost Trader Program</p>
                                <p class='mfs-2 mt-1' style={{ cursor: 'pointer' }} onClick={() => navigate('/faq')}>FAQ</p>


                                {/* <p class='mfs-2'>Pages</p> */}
                            </div>
                            {/* <div className='col-sm-3 col-5 text-start'>
                            <p class='fwsb mfs-3'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                        </div> */}
                            {/* <div className='col-sm-3 col-5 text-start'>
                            <p class='fwsb mfs-3'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                        </div> */}
                            <div className='col-sm-7 col-11 text-start'>
                                <p class='fwsb mfs-3'>Follow Us</p>
                                <div class='d-flex gap-1 align-items-center  gap-4 mfs-3 mt-2'>

                                    <p class=''><i class="bi bi-facebook"></i></p>
                                    <p class=''><i class="bi bi-instagram"></i></p>
                                    <p class=''><i class="bi bi-twitter-x"></i></p>
                                </div>
                                <div>
                                    <div class='d-flex gap-2 mt-3'>
                                        <b>Company:</b>
                                        <p>Thy Wealth</p>
                                    </div>
                                    {/* <div class='d-flex gap-2 mt-2'>
                                        <b>Address:</b>
                                        <p>H21, Home Scene Building, Healthscape, Forbach, Mauritius</p>
                                    </div>
                                    <div class='d-flex gap-2 mt-2'>
                                        <b>Email: </b>
                                        <p>Three@3sum.ai</p>
                                    </div>
                                    <div class='d-flex gap-2 mt-2'>
                                        <b>Mobile:</b>
                                        <p>971562299443</p>
                                    </div> */}

                                    <div class='d-flex gap-2 mt-2'> 
                                        <p>3Sum is an IP owned by Thy Wealth, Mauritius, a global business approved under the Financial Services Commission.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <br /><br /><br />

            </div>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ zIndex: "9999" }}>
                <div class="modal-dialog modal-xl modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5 org" id="exampleModalLabel">Disclaimer</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class='  text-start  '  >
                                <div class='mt-3'>
                                    <p><b>General Information Only:</b></p>
                                    <p class='mt-1'>The information provided on this website/platform, including but not limited to all written
                                        content, tools, and resources, is for general informational purposes only. It should not be
                                        considered as financial, investment, legal, or tax advice. Users are encouraged to seek
                                        advice from a qualified financial professional regarding their specific financial needs and
                                        objectives.</p>
                                </div>
                                <div class='mt-3'>
                                    <p><b>Non-Solicitation Clause:</b></p>
                                    <p class='mt-1'>This platform is not intended to solicit investments, clients, or business in jurisdictions
                                        where such solicitation is prohibited by law. The services and content provided are not an
                                        invitation or an offer to buy or sell any financial instruments, securities, or investment
                                        products. Accessing this platform or interacting with its content does not constitute an
                                        agreement to enter into any financial or advisory relationship.</p>
                                </div>
                                <div class='mt-3'>
                                    <p><b>Non-Advisory Clause:</b></p>
                                    <p class='mt-1'>This platform does not provide personalized financial, legal, or investment advice. While
                                        tools, educational resources, and general market information are provided, these are not a
                                        substitute for professional advice tailored to individual circumstances. Decisions made
                                        based on the content of this platform are solely the responsibility of the user.</p>
                                </div>
                                <div class='mt-3'>
                                    <p><b>Risk Disclaimer:</b></p>
                                    <p class='mt-1'>All investments carry inherent risks, and users should conduct their own due diligence
                                        before making any investment decisions. Past performance is not indicative of future
                                        results. Users are reminded that financial markets are volatile, and trading or investing in
                                        any asset class, including digital assets, tokens, and derivatives, involves significant risk of
                                        loss.</p>
                                </div>
                                <div class='mt-3'>
                                    <p><b>No Guarantee of Accuracy or Completeness:</b></p>
                                    <p class='mt-1'>Although every effort has been made to ensure the accuracy of the information provided,
                                        this platform makes no guarantees or warranties, express or implied, regarding the
                                        completeness, timeliness, or accuracy of the information provided. The platform shall not
                                        be held liable for any errors, omissions, or delays in the content provided.</p>
                                </div>
                                <div class='mt-3'>
                                    <p><b>Regulatory Compliance:</b></p>
                                    <p class='mt-1'>Users are responsible for ensuring that their use of this platform complies with the laws and
                                        regulations of their respective jurisdictions. This platform operates in accordance with
                                        applicable local laws and does not intend to contravene financial regulations in any region.</p>
                                </div>
                                <div class='mt-3'>
                                    <p><b>Third-Party Content:</b></p>
                                    <p class='mt-1'>The platform may feature links to third-party websites or resources. These links are
                                        provided solely for convenience and do not imply endorsement or responsibility for the
                                        content, services, or products offered by those third parties.</p>
                                </div>
                                <div class='mt-3'>
                                    <p><b>No Warranty or Liability:</b></p>
                                    <p class='mt-1'>This platform and its operators disclaim all warranties, express or implied, regarding the
                                        content and services provided. The platform and its operators shall not be liable for any
                                        direct, indirect, incidental, consequential, or punitive damages arising from the use of or
                                        reliance on this platform.</p>
                                </div>
                                <div class='mt-3'>
                                    <p><b>Copyright Notice:</b></p>
                                    <p class='mt-1'>All brand logos and other proprietary information featured on this platform are the
                                        copyright of their respective owners. The use of such materials is in accordance with existing
                                        agreements and partnerships. Unauthorized use or reproduction is strictly prohibited.</p>
                                </div>
                                <div class='mt-3'>
                                    <p><b>Non-Endorsement Clause:</b></p>
                                    <p class='mt-1'>This platform does not promote or endorse any brand, product, or service in a manner that
                                        might conflict with the respective brand’s policies or guidelines. Any references to third-
                                        party brands or services are for informational purposes only.</p>
                                </div>
                                <div class='mt-3'>
                                    <p><b>For Informational Purposes Only:</b></p>
                                    <p class='mt-1'>The tools, resources, and content provided herein are for informational purposes only. This
                                        platform does not constitute an offer, promotion, or recommendation of any specific
                                        financial product or service.</p>
                                </div>
                                <div class='mt-3'>
                                    <p><b>Jurisdictional Notice:</b></p>
                                    <p class='mt-1'>The content of this platform is not directed at individuals in any jurisdiction where the use
                                        or distribution of such content is prohibited by law or regulation. It is the user&#39;s
                                        responsibility to ensure compliance with applicable local laws and regulations.</p>
                                </div>
                                <div class='mt-3'>
                                    <p><b>Acceptance of Terms:</b></p>
                                    <p class='mt-1'>By using this platform, you acknowledge that you have read and understood this disclaimer
                                        and agree to its terms. If you do not agree with any part of this disclaimer, you are advised
                                        to discontinue the use of this platform.</p>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="sumBtn" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer