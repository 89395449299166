import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const GostTrader = () => {
       useEffect(() => {
                AOS.init({ duration: 2000 });
            }, [])
    return (
        <div>
            <div class='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>

                <div class='d-flex align-items-center justify-content-center  col-11 m-auto rounded-4 bg-dark ' style={{ height: '80%' }}>
                    <div class='col-11 m-auto'>

                        <p class='mfs-7 fwh org'data-aos="zoom-in">Ghost Trader Program</p>
                        <p class='mfs-6 text-white fwl' data-aos="zoom-in-up">Write Your Financial Success Story</p>
                        <p class=' tJustify mt-2 text-white' data-aos="zoom-in-up">Just as a ghostwriter helps you craft your story without putting pen to paper, our <b>Ghost Trader Program</b>  allows you to shape your financial future without the need to be in the market every day. Whether you’re a trader looking to build your career or an investor seeking top-tier talent to manage your portfolio, our program is designed to connect you with the best in the industry.</p>
                    </div>
                </div>
            </div>
            <div class='col-11 m-auto text-start'>

                <div>
                    <p class='fs-3 fwb mt-5'data-aos="fade-up">Become a Ghost Trader</p>
                    <p class='mt-2 tJustify'data-aos="fade-up">Imagine writing a bestseller, but instead of words, you’re crafting financial success. As a trader or investment consultant, you can become the “ghostwriter” of high-net-worth clients’ portfolios. Enroll in our exclusive Ghost Trader Program by contacting us at ghosttrader@thywealth.com and start your journey with our Paper Trading Challenge.</p>
                    <p class='mt-2 tJustify'data-aos="fade-up">This challenge spans three rigorous levels over 12 trading weeks, testing your trading expertise and strategy in real market conditions. Pass the challenge, and you’ll be listed in our Ghost Trader database, where elite clients and corporate institutions will seek your services. This is your chance to create financial masterpieces and be recognized for your skill, potentially leading to placements with top corporate institutions as a trader on client desks or within prop funds.</p>
                    <p class='mt-2 tJustify'data-aos="fade-up"> Interested in enrolling? Contact us at ghosttrader@thywealth.com to get started.</p>
                </div>

                <div>
                    <p class='fs-3 fwb mt-5'data-aos="fade-up">Hire a Ghost Trader</p>
                    <p class='mt-2 tJustify'data-aos="fade-up">Just like hiring a ghostwriter to bring your ideas to life, hiring a Ghost Trader allows you to leverage the expertise of top professionals to manage and grow your portfolio. Our Ghost Trader Program is an exclusive opportunity for elite clients with account sizes above US$150K. We connect you with traders who have proven their mettle, passing our rigorous selection process where 97% don’t make the cut.</p>
                    <p class='mt-2 tJustify'data-aos="fade-up">These traders are like the best-selling authors of the financial world—highly skilled, thoroughly vetted, and ready to help you achieve your investment goals. To learn more or to begin the process of hiring a Ghost Trader, reach out to us at ghosttrader@thywealth.com.</p>
                    <p class='mt-2 tJustify'data-aos="fade-up">Remember, while we provide access to these elite traders, the funds always remain under your control, and you can negotiate the terms directly with them.</p>
                </div>

                <div>
                    <p class='fs-3 fwb mt-5'data-aos="fade-up">Corporate Hiring: Access Top Trading Talent</p>
                    <p class='mt-2 tJustify'data-aos="fade-up">For family offices, independent asset managers, prop funds, and hedge funds looking to add expert traders to their teams, our Ghost Trader Program offers a unique advantage. Much like finding the perfect ghostwriter to convey your vision, our program connects you with top trading talent who have demonstrated exceptional skills through our 12-week Paper Trading Challenge.</p>
                    <p class='mt-2 tJustify'data-aos="fade-up">This is more than just a hiring process—it’s an opportunity to onboard professionals who can craft financial success stories within your organization. Interested in hiring through our program? Contact ghosttrader@thywealth.com to discuss how we can help you build a winning team.</p>
                </div>

                <div>
                    <p class='fs-3 fwb mt-5'data-aos="fade-up">Disclaimer</p>
                    <p class='mt-2 tJustify'data-aos="fade-up">While we pride ourselves on our thorough selection process, clients are advised to conduct their own interviews with referred traders. The management of funds should always remain under the client’s control in their accounts. Clients can negotiate their own terms with the traders they choose to work with.</p>
                </div>
            </div>
            <br />
            <br />
        </div>
    )
}

export default GostTrader