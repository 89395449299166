import axios from 'axios';
import React, { useEffect } from 'react' 
import { API } from './url';
import Swal from 'sweetalert2';
const Frame = () => {
  const embedKey = '386c786dfc954399ac56c18064af14a8';
  let token = localStorage.getItem("sum3UserAccessFinChatToken");
  let sum3UserAccessToken = localStorage.getItem("sum3UserAccessToken");

  useEffect(() => {
    function handleIframeReady(event) {
      if (event.data !== 'READY') return; 
      const targetWindow = window.frames['finchat']; 
      if (!targetWindow) return;
      targetWindow.postMessage({ token }, 'https://enterprise.finchat.io');
    } 
    window.addEventListener('message', handleIframeReady); 
    return () => window.removeEventListener('message', handleIframeReady);
  }, [token]); 

  const handelCheckService = async (id) => {
    try {
        const response = await fetch(`${API}/products/product-usage/${id}`, {
            method: 'POST',
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
                  Authorization: `Bearer ${sum3UserAccessToken}`
            },   // Fixed the 'body' casing and converted email to JSON format
        }); 
        const data = await response.json(); 
    } catch (error) {  Swal.fire({
            text: error,
            icon: "error"
        });
    }
}
  return (
    <iframe
      name="finchat"
      src={`https://enterprise.finchat.io/${embedKey}`}
      style={{ border: 'none', width: '100%', height: '80vh' }}
      class='rounded-5 '
    
    />
  );
}; 
export default Frame;