import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  let sum3UserAccessToken = localStorage.getItem("sum3UserAccessToken");
  let sum3UserRole = localStorage.getItem("sum3UserRole");

  useEffect(() => {
    const nav = document.querySelector('.navbarMain');
    let lastScrollY = window.scrollY;
    window.addEventListener('scroll', () => {
      if (lastScrollY < window.scrollY) {
        nav.classList.add('navbarMain--hidden');
      } else {
        nav.classList.remove('navbarMain--hidden');
      }
      lastScrollY = window.scrollY
    })
  })

 

  useEffect(() => {
    const nav = document.querySelector('.menus');

    if (isActive) {
      nav.classList.add('active');
    } else {
      nav.classList.remove('active');
    }

    // Clean up when the component unmounts or isActive changes
    return () => {
      nav.classList.remove('active');
    };
  }, [isActive]);

  const navigate = useNavigate()

  const movePage = (path) => {
    setIsActive(false)
    navigate(path)
  }

  const location = useLocation();

  const logOut = () => {
    localStorage.clear()
    setIsActive(false)
    navigate('/log-in')
  }




  return (
    <div>

      <div class='navbarMain' style={{ width: '100%', overflowX: 'hidden' }}>
        <div class='col-11 m-auto d-flex  align-items-center justify-content-between'>
          <div class='position-relative' onClick={() => movePage('/')} style={{ cursor: 'pointer' }}>
            {((location?.pathname == '/invest1') || (location?.pathname == '/sum-bot')) ?
              <img src={require('../Images/footerLogo.png')} class='navLogo ' style={{ height: '50px' }} />
              :
              <img src={require('../Images/navLogo.png')} class={!isActive ? 'navLogo ' : 'navLogo hide'} style={{ height: '50px' }} alt="" />
            }
            <img src={require('../Images/footerLogo.png')} class={isActive ? 'navLogo ' : 'navLogo hide'} style={{ height: '50px', position: 'absolute', top: '0', left: '0' }} />
          </div>
          <div>
            {/* <div class={isActive ? 'hamburger is-active' : 'hamburger'} id="hamburger" onClick={() => setIsActive(!isActive)} >
                        <span class="line line1"></span>
                        <span class="line line2"></span>
                        <span class="line line3"></span>
                    </div> */}

            <div class={isActive ? 'hamburger2 is-active' : 'hamburger2'} id="hamburger2" onClick={() => setIsActive(!isActive)} >
              <span class={`lines ${isActive ? 'lines1 active' : 'lines1'}`}></span>
              <span class={`lines ${isActive ? 'lines2 active' : 'lines2'}`}></span>
              <span class={`lines ${isActive ? 'lines3 active' : 'lines3'}`}></span>
            </div>
          </div>
        </div>
      </div>
      <div class='menus d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100vh', position: 'fixed', top: '0', right: '0', background: '#424242', zIndex: '1', overflowX: 'hidden' }}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <div class='d-grid justify-content-center  align-items-center' style={{ height: '100%' }}>
            <div class='d-grid gap-2 mt-5' style={{ color: '#FF823C' }}>
              {/* <p class='fs-1 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage('/')}>Home</p> */}
              {/* <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage('/basic-invest')}>Basic</p>
              <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage('/intermediate-invest')}>Intermediate</p>
              <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage('/pro-invest')}>pro</p> */}

              {(sum3UserAccessToken && sum3UserRole) &&
                <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage(`/${sum3UserRole}-invest`)}>Dashboard</p>
              }
              
              <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage('/our-story')}>Our Story</p>
              <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage('/our-products')}>Our Products</p>
                <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage('/sum-bot ')}>Sum-Bot</p>
              <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage('/quantum-global')}>Portfolios 3.O</p>
              <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage('/ghost-trader')}>Ghost Trader Program</p>
              <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage('/faq ')}>FAQ</p>
              {(!sum3UserAccessToken && !sum3UserRole) &&
                <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage('/log-in')}>Log-in</p>
              }
              {(sum3UserAccessToken && sum3UserRole) &&
                <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={() => movePage('/profile')}>Profile</p>
              }

              {(sum3UserAccessToken && sum3UserRole) &&
                <p class='fs-3 fw-semibold' style={{ cursor: 'pointer' }} onClick={logOut}>Log-Out</p>
              }



  
            </div>
          </div>

        </div>
      </div>
    </div>

  )
}

export default Navbar