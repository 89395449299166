import React from 'react'

const Loader = () => {
    return (
        <div class='d-flex justify-content-center align-items-center' style={{width:'100%', height:'100vh', position:'fixed',top:'0', left:'0', background:'rgba(255,130,60,0.5)',zIndex:'5'}}>
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default Loader