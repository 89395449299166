import React, { useEffect, useRef } from 'react'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import './home.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomeFooterTop = () => {

    const refHome1 = useRef(null);
    useEffect(() => {
      AOS.init({ duration: 2000 });
  }, [])

    useEffect(() => {
        const p1 = document.querySelector(".p1");
        const p2 = document.querySelector(".p2");
        const p3 = document.querySelector(".p3");
        const p4 = document.querySelector(".p4");
        const p5 = document.querySelector(".p5");
        const p6 = document.querySelector(".p6");
        const p7 = document.querySelector(".p7");
        const p8 = document.querySelector(".p8");
        const p9 = document.querySelector(".p9");
        const p10 = document.querySelector(".p10"); 
       
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
                p1.classList.add("active")
                p2.classList.add("active")
                p3.classList.add("active")
                p4.classList.add("active")
                p5.classList.add("active")
                p6.classList.add("active")
                p7.classList.add("active")
                p8.classList.add("active")
                p9.classList.add("active")
                p10.classList.add("active") 
            } else {
                p1.classList.remove("active"); 
                p2.classList.remove("active"); 
                p3.classList.remove("active"); 
                p4.classList.remove("active"); 
                p5.classList.remove("active"); 
                p6.classList.remove("active"); 
                p7.classList.remove("active"); 
                p8.classList.remove("active"); 
                p9.classList.remove("active"); 
                p10.classList.remove("active"); 

            }
          },
          { rootMargin: "0px", threshold: 0.5 }
        );
        if (refHome1.current) {
          observer.observe(refHome1.current);
        }
        return () => {
          if (refHome1.current) {
            observer.unobserve(refHome1.current);
          }
        };
      }, [refHome1]);
  return (
      <div class='col-11 m-auto rounded-4  position-relative' style={{ height: '80vh', border: '0.5px solid #FF823C', background: 'rgba(255,130,60,0.1)' , overflow:'hidden'}}    ref={refHome1}>
        <ParallaxProvider>
        <div class='d-flex justify-content-between h-100'>
            <div class='d-flex align-items-end h-100 col-2'>
                <Parallax translateY={[-50, -500]} > 
                <div class='col-11'  >
                    <img src={require('../../Images/rgbArrowUp.png')} style={{ width: '100%' }} alt="" />
                </div>
                </Parallax>
            </div>
            <div class='d-flex h-100 col-2 justify-content-end'>
            <Parallax translateY={[0, 80]} > 
                <div class='col-11'  >
                    <img src={require('../../Images/rgbArraoDown.png')} style={{ width: '100%' }} alt="" />
                </div>
                </Parallax>

            </div>
        </div>
        <div class=' position-absolute top-0 left-0 rounded-4' style={{ width: '100%', height: '100%' }}> 
            <div class='col-lg-8 col-md-9 col-11 m-auto mt-5'>
                <p class='fbnh mfs-7 org fweb  '  >Level up your portfolio with us.</p>

            </div>
        </div>
        <div class='portfoioList fwsb rounded-5 mfs-3 p1'>Forex </div>
        <div class='portfoioList fwsb rounded-5 mfs-3 p2'>Indices </div>
        <div class='portfoioList fwsb rounded-5 mfs-3 p3'>Commodities </div>
        <div class='portfoioList fwsb rounded-5 mfs-3 p4'>Gold </div>
        <div class='portfoioList fwsb rounded-5 mfs-3 p5'>Silver </div>
        <div class='portfoioList fwsb rounded-5 mfs-3 p6'>Stocks </div>
        <div class='portfoioList fwsb rounded-5 mfs-3 p7'>Equity</div>
        <div class='portfoioList fwsb rounded-5 mfs-3 p8'>Cryptos </div>
        <div class='portfoioList fwsb rounded-5 mfs-3 p9'>AI Investing </div>
        <div class='portfoioList fwsb rounded-5 mfs-3 p10'>Algo Trading</div>
</ParallaxProvider>
    </div>
  )
}

export default HomeFooterTop