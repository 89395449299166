import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { CgMenuRightAlt } from 'react-icons/cg'
import { FiArrowUpRight } from 'react-icons/fi'

const LoginLanding = ({screen}) => {

    const [togTwo, setTogTwo] = useState(false)
    const [togDumy, setTogDumy] = useState(false) 
    const [check, setCheck] = useState(false)

    const count = ()=>{
      for (let i = 0; i < 10; i++) {
        setTimeout(() => {
          if (i % 2 === 0) { 
            setCheck(true);
          } else {
            setCheck(false);
          }
        }, i * 4000); // Multiply the delay by 'i' to ensure it increments at intervals
      }
    }

 
    useEffect(() => {
      AOS.init({ duration: 2000 });
      count() 
    }, [])
  

   
   
  
    useEffect(() => {
      let rng = document.querySelector('.ring')
      setTimeout(() => {
        rng.classList.add('active')
      }, 1000)
    }, [])
  return (
    <div>
         <div class='d-flex align-items-center justify-content-center position-relative ' style={{ height: '100vh', overflowX: 'hidden' }}>
          <div class='col-11'>
            <div class='text-start '>
              <p class='display-3 fwsb d-flex flex-wrap ' data-aos="fade-up">Lets    Establish  Your  Skill Level, 
                {/* <span class='roundbox1 mfs-5 rounded-5  mt-sm-2 mx-2'>
                <span class='fs-5' style={check ? { background: "#FF823C", color: "#ffffff",transition:'2s' } : { background: "transparent", color: "#424242",transition:'2s' }}>2X</span>
               <span class='fs-2 fw-bold' style={!check ? { background: "#FF823C", color: "#ffffff",transition:'2s'  } : { background: "transparent", color: "#424242",transition:'2s'  }}><FiArrowUpRight /></span>
               </span>  */} 
                </p>
              <p class='display-3 fwsb d-flex flex-wrap' data-aos="fade-up">
                <span class='ring'><img class='m-0 p-0' src={require('../../Images/Union.png')} alt="" /></span>
                 <span class=' '> Customize Your Package</span>  
                     {/* <span class='roundbox2 mfs-5 rounded-5 mt-2 mx-2'><span class='fs-2 fw-bold' ></span><span class='fs-2 fw-bold' ></span> <span class='dumyToogle fs-2 fw-bold' style={check ? { left: '4px', transition: '2s' } : { right: '4px', transition: '2s' }}  ><CgMenuRightAlt /></span></span> */}
                      </p>
              <p class='display-3 fwsb' data-aos="fade-up">   And Level Up Your </p>
              <div class='roundbox3 rounded-5' data-aos="fade-up" style={check?{background:'rgba(255,130,60)',transition:'1s',color:'white'} : {background:"rgba(255,130,60,0.1)", transition:'1s'}}><div class='roundboxEff3'></div><p class='display-3 fwsb px-3'>Experience</p> <span class='fs-3'><i class="bi bi-lightning-charge-fill"></i></span> </div>

              {/* <p class='mfs-7'>WE’LL SIGNIGICANTLY   INCRESE THE PROFIT   AND LEVEL UP YOUR   PORTFOLIO</p> */}
              <button class='sumBtn mfs-4 mt-5  rounded-5' style={{ zIndex: '100px' }} onClick={()=>screen('third')} >Start Quiz Now</button>
            </div>
          </div>
          <div class='position-absolute' style={{ bottom: '0', left: '0' }}>
            <div class="a-section">
              <div class="a-section-marquee-box ">
                <h2 class="marquee-text mfs-7 m-0 p-0">Accessible. Intuitive. Empowering.</h2>
                <h2 class="marquee-text mfs-7 m-0 p-o mx-3">Accessible. Intuitive. Empowering.</h2>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default LoginLanding