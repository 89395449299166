import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  let sum3UserAccessToken = localStorage.getItem("sum3UserAccessToken");
  let sum3UserRole = localStorage.getItem("sum3UserRole");

  if (!sum3UserAccessToken && !sum3UserRole) {
    return <Navigate to="/log-in" />;
  }

  return <>{children}</>;
};
