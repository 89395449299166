import React from 'react'


const faq = [
    {
        qus: "What is 3Sum?",
        ans: "<b>3Sum</b> is an innovative platform designed for beginner traders, providing all the essential tools, guidance, and support in one place. We’ve partnered with internationally recognized and well-regulated institutions to create a secure and adaptive trading environment tailored to your needs. <b>3Sum </b> is a brand of Thy Wealth Mauritius, a registered Global Business Company licensed by the Financial Services Commission Mauritius."
    },
    {
        qus: "How secure is my investment?",
        ans: "3Sum is just a facilitator, so you never carry any risk on the platform itself. Your actual funds are held with top-tier banks, to which you make direct transfers. These funds are further allocated to your trading account with well-regulated and reputable global platform providers. These providers adhere to tier capital requirements of their regulators and keep funds in a segregated format. You are a direct client of these service providers, and for more detailed information on the regulations for each provider, you can write to us at <b> partnerships@thywealth.com</b>"
    },
    {
        qus: "What if Thy Wealth or 3Sum ceases business?",
        ans: "Even if Thy Wealth or 3Sum were to cease operations, your funds remain secure. Since 3Sum is only a facilitator, your funds are held with top-tier banks and allocated to your trading accounts with well-regulated global platform providers. These providers adhere to strict regulatory requirements, including maintaining segregated accounts for client funds, ensuring that your investments are protected regardless of 3Sum’s operational status."
    },
    {
        qus: "How does 3Sum help beginner traders?",
        ans: "3Sum is built around the idea of making trading accessible to everyone. When you sign up, you’ll answer a few simple questions to gauge your experience level. Based on your score, we guide you to the best platform, suggest a starting amount, and curate a trading environment that includes features like limited leverage on high-risk assets and copy trading options to help you learn and grow safely."
    },
    {
        qus: "How do I switch platforms or open multiple accounts with different providers?",
        ans: "If you wish to switch platforms or open multiple accounts with different providers, this can be arranged through a personal consultation. Please write to us at Consult@thywealth.com to discuss your options."
    },
    {
        qus: "What if I want to add or delete a service being offered as part of my package deal?",
        ans: "Deletion of services is not required. If you stop using a service, it will be considered archived, allowing you to reactivate it if you need it in the future. However, if you wish to add a service that is not included in your current package, simply select the desired service from our services page and follow the steps provided to add it to your package."
    },
    {
        qus: "What is the Ghost Trader Program?",
        ans: "The <b>Ghost Trader Program</b> is a unique initiative that allows experienced traders to prove their skills through a rigorous Paper Trading Challenge. Upon passing, they are listed in our exclusive database, where high-net-worth clients and corporate institutions can hire them to manage portfolios. For clients, it offers an opportunity to connect with top traders who have passed our stringent selection process."
    },
    {
        qus: "How do I become a Ghost Trader?",
        ans: "To become a Ghost Trader, you must enrol in our Paper Trading Challenge, which spans three rigorous levels over 12 trading weeks. If you pass, you’ll be listed in our database and introduced to high-net-worth clients and corporate institutions looking to hire skilled traders. To enroll, contact us at <b>ghosttrader@thywealth.com</b>."
    },
    {
        qus: "Who can hire a Ghost Trader?",
        ans: `<p>The <b> Ghost Trader Program</b> is open to elite clients with account sizes above <b> US$150K</b> who are looking for top-tier trading talent. Clients can connect with traders who have proven their mettle, passing our rigorous selection process where 97% don’t make the cut. To learn more about hiring a Ghost Trader, contact us at<b> ghosttrader@thywealth.com.</b></p>`
    },
    {
        qus: "What services does 3Sum offer?",
        ans:`
            <p>3Sum offers a range of services designed to enhance your trading experience, including :  <br /> <ul>
                <li><b>Specially Curated Trading Environment:</b>  Access to state-of-the-art platforms from internationally recognized and regulated institutions</li>
                <li><b>Copy Trading:</b>  Follow and replicate the trades of over 500 global expert traders.</li>
                <li><b>AI-Enabled Financial Research (SumBot3.0):</b> Receive market insights and analysis powered by AI.</li>
                <li><b>Learn How to Trade:</b> Access a comprehensive library, live training sessions, and a juniors program for younger family members.</li>
                <li><b>Live Trading Signals:</b> Get real-time, actionable signals from experienced consultants.</li>
                <li><b>Executive Coaching & Mindset Training:</b>  Develop a winning mindset with guidance from top coaches.</li>
                <li><b>One-on-One Consultation: </b> Personalized portfolio analysis and restructuring for larger clients. </li>
                <li><b>Customized Platform Solutions for Experts: </b> Tailored solutions for high-volume and algorithmic trading.</li>

            </ul>
            </p>`
    },
    {
        qus: "How does the Copy Trading feature work?",
        ans: "Our <b>Copy Trading </b>feature, offered through select platforms,  allows you to follow and replicate the trades of expert traders from around the world. You can review each trader’s performance history, global ranking, and personal details before committing as little as $200. This feature enables you to diversify your investments across different asset classes and strategies."
    },
    {
        qus: "What is SumBot3.0?",
        ans: "<b>SumBot3.0 </b>is an AI-enabled financial research assistant trained on millions of data nodes. It provides in-depth market analysis, insights, and research, covering the entire breadth and depth of the markets. SumBot3.0 is designed to help you make informed decisions and stay ahead in your trading journey."
    },
    {
        qus: " How do I access Live Trading Signals?",
        ans: " Our<b> Live Trading Signals</b> are available through a restricted page on our platform and via subscription to our closed Telegram channel. These signals are provided by a group of experienced consultants and are regularly updated during live market hours to help you make timely and informed trading decisions."
    },
    {
        qus: " . What is the benefit of the Executive Coaching & Mindset Training?",
        ans: " Our<b> Executive Coaching & Mindset Training</b> service connects you with top coaches who have worked on Wall Street and other leading trading floors. These coaches help you develop the psychological resilience and winning mentality needed to manage your trading activities like a successful business."
    },
    {
        qus: " Can I involve my family members in learning how to trade?",
        ans: "Yes! 3Sum offers a <b>juniors program </b>as part of our 'Learn How to Trade' service. This program is designed to introduce younger family members to the basics of trading, ensuring they start their financial journey with a strong foundation."
    },
    {
        qus: ". What is the Customized Platform Solutions service?",
        ans: "For expert traders looking to push the boundaries of their trading, we offer <b> Customized Platform Solutions</b>. These include virtual global cloud servers in major cities, faster execution speeds, and support for developing algorithmic trading strategies. These solutions are tailored to meet the demands of high-frequency and volume trading."
    },
    {
         
        qus: "How can I contact 3Sum for more information?",
        ans: "For any inquiries or to learn more about our services, feel free to contact us at <b>ghosttrader@thywealth.com</b>. We’re here to help you every step of the way on your trading journey."
    },
]
const FAQ = () => {
    return (
        <div class='col-11 m-auto'>
            <br />
            <p class='fs-1 org mt-5 fw-bold'> FAQ</p>
            <div>
            <div className="accordion" id="categoryAccordion">
              {
                faq?.map((el, i) => {
                  return (
                    <div key={i} class='accordion-item mt-4 rounded-3'>
                      <button
                        className="accordion-button rounded-3 "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#category${i}`}
                        aria-expanded="true"
                        aria-controls={`category${i}`}
                        style={{color:'#424242'}}
                      >

                        <div class='d-flex align-items-center  justify-content-between ' style={{color:'#424242'}}>
                          <div>
                            <p class='ts-3'><span>{i + 1}.</span> <span class='mx-2' dangerouslySetInnerHTML={{ __html: el?.qus }}></span></p>
                          </div>
                        </div>
                      </button>
                      <div className="accordion-collapse collapse text-start" aria-labelledby={`heading${i}`} data-bs-parent="#categoryAccordion" id={`category${i}`}>
                        <div class="card card-body border-0">
                          <p class='ts-2 tjustify' style={{color:'#424242'}} dangerouslySetInnerHTML={{ __html: el?.ans }}></p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }

            </div>
            </div>
            <br /><br />
        </div>
    )
}

export default FAQ