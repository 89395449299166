import React, { useEffect, useState } from 'react'
import './login.css'
import { API } from '../url';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';


const Profile = () => {
    const [profile, setProfile] = useState()
    const navigate = useNavigate()

    const sum3UserAccessToken = localStorage.getItem('sum3UserAccessToken')

    const getProfileDetails = async () => {
        try {
            const response = await fetch(`${API}/users/profile`, {
                method: 'get',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3UserAccessToken}`
                },
            });
            if (!response.ok) {
                throw new Error('Failed to Received Question');
            }
            const data = await response.json();
            setProfile(data?.payload)
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    useEffect(() => {
        getProfileDetails()
    }, [])

    const handleOnChange = (fieldName, value) => {
        const updatedDetails = { ...profile, [fieldName]: value }; 
       
        setProfile(updatedDetails);
    };

     

    const updateProfileDetails = async () => {
        try {
            const response = await fetch(`${API}/users/profile`, {
                method: 'post',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3UserAccessToken}`
                },
                body: JSON.stringify(profile)
            });
            if (!response.ok) {
                throw new Error('Failed to send Details');
            }
            const data = await response.json();
            Swal.fire({
                text: data?.message,
                icon: "success",
            });
            getProfileDetails()
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };


   
    return (
        <div class='loginBg'>
            <div class='col-lg-8 col-11'>
                <FaUserCircle class='org' style={{fontSize:'150px'}}/>
                <p class='org fw-bold fs-3'>{profile?.email}</p>
                <br />
                <div class='col-12 rounded-5 bg-white p-2 ' style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>
                    <br />
                    <div class='col-11 m-auto row justify-content-between'>  
                        <div class="col-lg-6 ">
                            <div class="col-11 m-auto form-floating mt-3 p-0 border-danger">
                                <input type="name" class="form-control " id="name" placeholder="name"   value={profile?.fullname?.charAt(0).toUpperCase() + profile?.fullname?.slice(1)} onChange={(e)=>handleOnChange('fullname', e.target.value)} style={{ border: '0.5px solid #FF823C' }} />
                                <label for="name">Name</label>
                            </div>
                        </div>
                        {/* <div class="col-lg-6 "> 
                            <div class="col-11 m-auto form-floating mt-3 p-0 border-danger">
                                <input type="email" class="form-control " id="gmail" placeholder="sum@gmail.com" readOnly value={profile?.email} style={{ border: '0.5px solid #FF823C' }} />
                                <label for="gmail">Email</label>
                            </div>
                        </div> */}
                        <div class="col-lg-6 "> 
                            <div class="col-11 m-auto form-floating mt-3 p-0 border-danger">
                                <input type="text" class="form-control " id="country" placeholder="country"   value={profile?.country?.charAt(0).toUpperCase() + profile?.country?.slice(1)} onChange={(e)=>handleOnChange('country', e.target.value)} style={{ border: '0.5px solid #FF823C' }} />
                                <label for="country">Country</label>
                            </div>
                        </div>
                        <div class="col-lg-6 "> 
                            <div class="col-11 m-auto form-floating mt-3 p-0 border-danger">
                                <input type="text" class="form-control " id="city" placeholder="city"   value={profile?.city?.charAt(0).toUpperCase() + profile?.city?.slice(1)} onChange={(e)=>handleOnChange('city', e.target.value)} style={{ border: '0.5px solid #FF823C' }} />
                                <label for="city">City</label>
                            </div>
                        </div>
                        <div class="col-lg-6 "> 
                            <div class="col-11 m-auto form-floating mt-3 p-0 border-danger">
                                <input type="text" class="form-control " id="Mobile" placeholder="Mobile"   value={profile?.mobile} onChange={(e)=>handleOnChange('mobile', e.target.value)} style={{ border: '0.5px solid #FF823C' }} />
                                <label for="Mobile">Mobile</label>
                            </div>
                        </div>
                    </div> 
                    <div class=' col-sm-10 col-11 m-auto mt-3 d-flex gap-sm-4 gap-2 align-items-center '>
                    <button class='sumBtn mfs-3  form-control  rounded-5' onClick={updateProfileDetails}>Profile Changes</button>
                    <button class='sumBtn mfs-3  form-control  rounded-5' onClick={()=>navigate('/level-up')}>Level Up +</button>


                    </div>

                    <br />
                       
                    </div> 
                </div>

            </div>
            )
}

            export default Profile


