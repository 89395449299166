import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Frame from './Frame';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API } from './url';

const SumBoatFream = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])

    let sum3UserAccessToken = localStorage.getItem("sum3UserAccessToken");
    let sum3UserRole = localStorage.getItem("sum3UserRole");
    const navigate = useNavigate()

    const handelCheckService = async (id) => {
        try {
            const response = await fetch(`${API}/products/product-usage/${id}`, {
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3UserAccessToken}`
                },   // Fixed the 'body' casing and converted email to JSON format
            });
            const data = await response.json();
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    }




    return (
        <div style={{ background: '#1A1A1A' }}  >
            <br />
            <div class='d-flex align-items-center justify-content-center mt-5' style={{ height: '100vh', width: '100%' }}>
                <div class='col-11 m-auto'>
                    {/* <button class='btn btn-outline-light rounded-5 fs-5 fwr px-4 ' data-aos="fade-down">Lorem Ipsum Dolor Sit AMet</button> */}
                    <div class='col-12 m-auto sumBotShadow' data-aos="fade-up">
                        <img src={require('../Images/framBg.png')} style={{ width: '100%' }} alt="" />
                    </div>
                    <div class='sumBotTitle' >
                        <p class='mfs-7 fwb org' data-aos="fade-in"   >SumBot3.0</p>
                        <p class='mfs-7 fwr text-white' data-aos="fade-up">AI-Enabled Financial Research</p>
                        <p class='fs-5 fwt col-10 m-auto tJustify text-white' data-aos="fade-up">Unlock the power of Al with SumBot3.0, your personal financial research assistant. Trained on billions of data points, SumBot3.0 delivers deep market insights and analysis to keep you ahead in the trading game.</p>
                        {/* <button class='sumBtn mfs-4 rounded-5 mt-3' >Log in</button> */}
                        {(!sum3UserAccessToken && !sum3UserRole) &&
                            <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/log-in')}>Log in</button>
                        }

                    </div>
                </div>
            </div>

            {(sum3UserAccessToken && sum3UserRole) &&

                <div class='col-10 m-auto  ' data-aos="zoom-in-up" onClick={() => handelCheckService("902973b9-c0f9-11ef-8e73-fa163e78f843")}>

                    <Frame />

                </div>
            }

            <br />
        </div>
    )
}

export default SumBoatFream